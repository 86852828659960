import React from 'react';
import './footer.css';

function Footer(props) {

    return (
        <React.Fragment>
            <div className="footer">
            
            </div>
        </React.Fragment>
    )

}


export default Footer;