import React from 'react';
import { Paper } from '@mui/material';

function Github(props) {

    return (
        <React.Fragment>
            <Paper>
                <h1>This is the Github Page</h1>
            </Paper>
            
        </React.Fragment>
    )

}


export default Github;